
























































import { popoStore } from '@/stores/popo-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  popoStore = popoStore
  decorators = []
  get imagesPerRow() {
    return this.$vuetify.breakpoint.smOnly ? 2 : 4
  }
}
